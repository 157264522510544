// NPM
import styled from 'styled-components'
import propTypes from 'prop-types'

// Constants
const APP_MAKER = 'appMaker'
const APP_USER = 'appUser'

// CSS based on role
const justifyContent = ({ role }) => {
  if (role === APP_USER) {
    return 'flex-start'
  } else if (role === APP_MAKER) {
    return 'flex-end'
  }
}

const backgroundColor = ({ role }) => {
  if (role === APP_USER) {
    return '#f4f4f4'
  } else if (role === APP_MAKER) {
    return '#8EC7C5'
  }
}

const borderRadius = ({ role }) => {
  if (role === APP_USER) {
    return '3px 21px 21px 21px'
  } else if (role === APP_MAKER) {
    return '21px 21px 3px 21px'
  }
}

const color = ({ role }) => {
  if (role === APP_USER) {
    return '#666'
  } else if (role === APP_MAKER) {
    return '#fff'
  }
}

const textAlign = ({ role }) => {
  if (role === APP_USER) {
    return 'left'
  } else if (role === APP_MAKER) {
    return 'right'
  }
}

// Styled components
export const Wrapper = styled.section.attrs({
  className: 'message'
})`
  padding: 0.5rem 1.5rem;
  position: relative;

  &:last-of-type {
    padding-bottom: 1.5rem;
  }

  &.outgoing {
    opacity: 0.75;
  }

  /*
  * outputs as .message_content {}, for example
  * and not .message { .message__content {} }
  * */
  .message {
    &__content {
     justify-content: ${justifyContent};
    }

    &__bubble {
      color: ${color};
      border-radius: ${borderRadius};
      background-color: ${backgroundColor};
    }

    &__meta {
      text-align: ${textAlign};
    }
  }
`

Wrapper.displayName = 'Wrapper'
Wrapper.propTypes = {
  role: propTypes.oneOf([
    APP_MAKER,
    APP_USER,
  ]).isRequired,
}
