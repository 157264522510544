// NPM
import { useEffect } from 'react'

// Util
const attachHandler = socketHandler => ({ event, handler }) => {
  if (!socketHandler.hasListeners(event)) {
    socketHandler.on(event, handler)
  }
}
const detachHandler = socketHandler => ({ event, handler }) => {
  if (socketHandler.hasListeners(event)) {
    socketHandler.off(event, handler)
  }
}

// Main
const useSocket = (socket, events) => {
  useEffect(() => {
    if (events.length > 0) {
      events.forEach(attachHandler(socket))
    }

    return () => {
      events.forEach(detachHandler(socket))
    }
  }, [socket, events])

  return socket
}

// Exports
export default useSocket
