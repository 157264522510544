import styled from 'styled-components'

export const ContentWrapper = styled.div.attrs({
  className: 'modal__content',
})`
  padding: 1rem;

  input {
    width: 320px;
  }

  .input-wrapper {
    padding: 0.25rem 0;
  }
`
export const ControlsWrapper = styled.div.attrs({
  className: 'modal__controls',
})`
  border: 1px solid #eee;
  padding: 1rem;
  text-align: right;

  Button {
    margin: 0 0.5rem;
  }
`
export const TextArea = styled.textarea.attrs(() => ({
  className: 'browse-listings-message'
}))`
  resize: none;
  width: 500px;
  border: 1px solid #eee;
`