import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Field } from 'formik'
import { Button, Input } from 'antd'
import { 
  ContentWrapper, 
  ControlsWrapper
} from './styled'

class SendApplyToListing extends React.PureComponent {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  handleSubmit = values => {
    const { onSubmit } = this.props

    onSubmit(values.listingID, 'sendApplyToListing')
  }

  renderField = ({field, form, props}) =>( 
    <Input 
      {...field} 
      {...props}
      placeholder="Listing Link"
    />
  )
  
  render() {
    const { onCancel } = this.props

    return (
      <Formik
        initialValues={{ listingID: '' }}
        onSubmit={this.handleSubmit}
      >
        {props => (
          <form onSubmit={props.handleSubmit}>
            <ContentWrapper>
              <h3>Send Apply to Listing</h3>
              <div className="input-wrapper">
                <Field 
                  name="listingID"
                  component={this.renderField}
                />
              </div>
            </ContentWrapper>
            <ControlsWrapper>
              <Button onClick={onCancel}>Cancel</Button>
              <Button onClick={props.handleSubmit}>Submit</Button>
            </ControlsWrapper>
          </form>
        )}
      </Formik>
    )
  }
}

export default SendApplyToListing
