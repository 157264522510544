// NPM
import React from 'react'
import styled from 'styled-components'
import propTypes from 'prop-types'

// Styled components
export const Wrapper = styled.footer.attrs({
  className: 'message__meta'
})`
  color: #2222228f;
  font-size: 12px;

  .status {
    padding: 0 0.25rem;

    .check {
      color: #38d948;
    }

    .check + .check {
      margin-left: -4px;
    }

    .failed {
      color: red;
    }
  }
`

Wrapper.displayName = 'Meta'

// Lib
const renderArray = (
  arr, // Arr If rendering React elements, you'll have to manually set the `key` of those elements.
  separator, // Str Character to be used as separator.
) => {
  if (arr.length === 0) {
    return null
  } else if (arr.length === 1) {
    return (<>{arr}</>)
  }

  let elements = []

  const filtered = arr.filter(Boolean)

  filtered.forEach((item, index) => {
    const isNotLastItem = index !== filtered.length - 1

    if (isNotLastItem) {
      elements = [...elements, item, separator]
    } else {
      elements = [...elements, item]
    }
  })

  return (<>{elements}</>)
}

export const renderStatus = ({
  status,
  seen,
}) => {
  const content = renderArray([
    status === 'SENDING' && 'Sending…',
    status === 'DELIVERED' && (<span key="delivered" className="check">✓</span>),
    seen && status !== 'FAILED' && (<span key="seen" className="check">✓</span>),
    status === 'FAILED' && (<span key="failed" className="failed">✕</span>),
  ])

  return (<span className="status">{content}</span>)
}

// Main
export const Meta = ({
  metadata,
  role,
  seen,
  source,
  status,
  timeReceived,
}) => {
  const { type } = source
  const { sender, receivingChannel } = metadata

  const sources = {
    twilio: 'SMS',
    messenger: 'Facebook Messenger'
  }
  const channel = sources[type]

  const sentByVia = [
    sender && `Sent by ${sender}`,
    receivingChannel && `via ${sources[receivingChannel]}`,
  ].filter(Boolean).join(' ')

  const metaArr = [
    role === 'appUser' && channel,
    role === 'appMaker' && sentByVia.length > 0 && sentByVia,
    timeReceived,
  ]

  const content = renderArray(metaArr, ' • ')

  return (
    <Wrapper>
      {content}
      {role === 'appMaker' && renderStatus({ seen, status })}
    </Wrapper>
  )
}

Meta.defaulProps = {
  metadata: {},
  role: null,
  seen: false,
  source: {},
  status: null,
  timeReceived: null,
}
Meta.propTypes = {
  metadata: propTypes.object,
  role: propTypes.string,
  seen: propTypes.bool,
  source: propTypes.object,
  status: propTypes.string,
  timeReceived: propTypes.string,
}
