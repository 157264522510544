// NPM
import React from 'react'
import propTypes from 'prop-types'
import uuid from 'uuid/v4'

// Internal components
import {
  Wrapper,
  Content,
  Bubble,
  Meta,
  ResendControls,
} from './components'

// Utils
const classNames = obj => {
  let cn = []

  Object.keys(obj).forEach(key => {
    if (obj[key]) {
      cn = [...cn, key]
    }
  })

  return cn.join(' ')
}

// Lib
// Trying a more declarative style in render funcitons
const renderCarousel = items => items.map(bubbleProps => {
  const { description, text } = bubbleProps
  const props = {
    ...bubbleProps,
    key: description || text,
  }

  return <Bubble { ...props } />
})

const renderImages = ({ images, ...rest }) => images.map(imgFields => {
  const splitURL = imgFields.mediaUrl.split('/')

  const props = {
    key: splitURL[splitURL.length - 1],
    ...rest,
  }

  return <Bubble {...props} {...imgFields} />
})

// Main
export const Message = ({
  ID,
  onRemove,
  onResend,
  role,
  type,
  actions,
  images,
  items,
  mediaUrl,
  metadata,
  onImageLoaded,
  seen,
  source,
  status,
  text,
  timeReceived,
}) => {
  const metaProps = {
    metadata,
    role,
    seen,
    source,
    status,
    timeReceived,
  }

  const handleImageLoaded = ID => onImageLoaded(ID)

  const bubbleProps = {
    actions,
    mediaUrl,
    onImageLoaded: handleImageLoaded,
    text,
    ID,
  }

  // Resend functions
  const onCommand = command => {
    const common = {
      originalID: ID,
      // Might be useful down the road
      ...(role === 'appMaker' && {
        originalSender: metadata.sender,
        originalChannel: metadata.receivingChannel,
      }),
    }

    if (command === 'remove') {
      return onRemove(common)
    } else if (command === 'resend') {
      return onResend({
        ...common,
        role,
        text,
        outgoingID: uuid(),
      })
    }
  }
  const cards = items.length > 1
  const batchImages = images.length > 1

  const contentClassName = classNames({
    withCards: cards,
    withImages: batchImages,
  })

  return (
    <Wrapper role={role} id={ID}>
      <Content className={contentClassName}>
        {role === 'appMaker' && status === 'FAILED' && <ResendControls onCommand={onCommand} />}
        {type === 'carousel' && renderCarousel(items)}
        {type === 'image' && renderImages({ ...bubbleProps, images })}
        {type === 'text' && <Bubble { ...bubbleProps } />}
      </Content>
      <Meta { ...metaProps } />
    </Wrapper>
  )
}

Message.displayName = 'Message'
// Props
Message.defaultProps = {
  actions: [],
  images: [],
  items: [],
  mediaUrl: null,
  metadata: {},
  onImageLoaded: () => null,
  seen: false,
  source: {},
  status: 'SENDING',
  text: null,
  timeReceived: null,
}
Message.propTypes = {
  ID: propTypes.string.isRequired,
  onRemove: propTypes.func.isRequired,
  onResend: propTypes.func.isRequired,
  role: propTypes.string.isRequired,
  type: propTypes.string.isRequired,
  actions: propTypes.array,
  images: propTypes.array,
  items: propTypes.array,
  mediaUrl: propTypes.string,
  metadata: propTypes.object,
  onImageLoaded: propTypes.func,
  seen: propTypes.bool,
  source: propTypes.object,
  status: propTypes.oneOf(['DELIVERED', 'FAILED', 'SENDING']),
  text: propTypes.string,
  timeReceived: propTypes.string,
}
