//NPM
import React from 'react'
import styled from 'styled-components'

// Styled components
const Wrapper = styled.h1.attrs({ className: 'placeholder' })`
  color: #999;
  font-size: 1.125rem;
  text-align: center;

  margin: 0;
  padding: 1.5rem 0;
`
Wrapper.displayName = 'Wrapper'

// Main
export const Header = ({ text, children, ...rest }) => (
  <Wrapper {...rest}>
    {text || children}
  </Wrapper>
)
Header.displayName = 'Header'

