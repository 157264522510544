import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  html,
  body,
  #root {
    font-family: sans-serif;

    height: 100vh;
    width: 100vw;

    overflow: hidden;
  }

  body {
    font-size: 16px;
    margin: 0;
  }
`

export const Layout = styled.div.attrs({
  className: 'wrapper',
})`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  height: 100%;
  width: 100%;
`
