// NPM
import styled from 'styled-components'

// Styled components
export const Content = styled.article.attrs({
  className: 'message__content',
})`
  display: flex;
  padding: 0.5rem 0;

  overflow-x: hidden;

  &.withImages,
  &.withCards{
    overflow-x: auto;

    max-width: 100%;
    width: 100%;

    & section {
      width: 100%;
    }
  }

  &.withCards {
    justify-content: flex-start;

    padding-left: 38.2%;
    max-width: 100%;
    width: 100%;

    & .imageFrame {
      max-height: 12rem;
      height: 12rem;
    }

    @media (min-width: 1280px) {
      padding-left: 61.8%;
    }

    @media (max-width: 1132px) {
      padding-left: 38.2%;
    }

    @media (max-width: 1024px) {
      padding-left: 23.6076%;
    }

    @media (max-width: 768px) {
      padding-left: 0;
    }
  }
`

Content.displayName = 'Content'
