// NPM
import { useState } from 'react'
import { remove } from 'ramda/src'
import moment from 'moment-timezone'
import { pick, mergeDeepRight, equals } from 'ramda/src'

// App
import { TIMEZONE } from '../../env'

// Constants
const DATE_RECEIVED_FORMAT = 'ddd MMMM D, YYYY'

// Utils
const updateWhere = (initial, { where, values }) => {
  const itemIndex = initial.findIndex(msg => {
    const msgData = pick(Object.keys(where), msg)
    const merged = mergeDeepRight(msgData, where)

    return equals(msgData, merged)
  })

  // TODO: Consider throwing error for not found
  // React, however, will not re-render if it sees no changes in state
  // when setting it with the same values so it might be alright to
  // return the `initial` for not found. Need to confirm this
  // assumption more thoroughly.
  const itemFound = itemIndex !== - 1

  let result = initial

  if (itemFound) {
    const updatedItem = { ...initial[itemIndex], ...values }

    result = [
      ...initial.slice(0, itemIndex),
      updatedItem,
      ...initial.slice(itemIndex + 1, initial.length),
    ]
  }

  // When used in Array.reduce(updateWhere, messageThread),
  // `result` will be `initial` of next call. Do not forget to
  // provied an initial value for `messageThread`.
  return result
}

const formatDateReceived = unixTimestap => ({
  timeReceived: moment.unix(unixTimestap).tz(TIMEZONE).format('h:mm A'),
  dateReceived: moment.unix(unixTimestap).tz(TIMEZONE).format(DATE_RECEIVED_FORMAT),
})

const createDateItem = ({ dateReceived }) => {
  const ID = moment.tz(dateReceived, DATE_RECEIVED_FORMAT, TIMEZONE).format('YYYY-DD-MM')

  return { role: 'date', ID, text: dateReceived }
}

const withAppend = setter => (...toAppend) => setter(current => [...current, ...toAppend])

const getStatus = (failedIDs, ID) => failedIDs.includes(ID) ? 'FAILED' : 'DELIVERED'

const removeMessageByID = (initial, ID) => {
  const indexToRemove = initial.findIndex(({ ID: MID }) => (MID === ID))

  let withRemoved = remove(indexToRemove, 1, initial)

  const indexBeforeRemoved = indexToRemove - 1
  const itemBeforeRemoved = (indexBeforeRemoved !== -1 && withRemoved[indexBeforeRemoved]) || null

  // Remove unnecessary date item
  if (itemBeforeRemoved && itemBeforeRemoved.role === 'date') {
    withRemoved = remove(indexBeforeRemoved, 1, withRemoved)
  }

  // When used in Array.reduce(removeMessage, messageThread),
  // `withRemoved` will be `initial` of next call. Do not forget to
  // provied an initial value for `messageThread`.
  return withRemoved
}

// Lib
const transformMessage = ({ received, ...rest }, lastSeenTimestamp) => ({
  ...rest,
  seen: lastSeenTimestamp - received >= 0,
  timestamp: received,
  ...formatDateReceived(received)
})

const assembleThread = ({
  messages,
  conversation,
  failedIDs,
}) => {
  let thread = []
  let lastDateAdded = null

  const { appUserLastRead } = conversation

  messages
    .filter(({ text }) => text !== '[deleted]')
    .forEach(msg => {
      const { ID } = msg
      const messageItem = {
        ...transformMessage(msg, appUserLastRead),
        status: getStatus(failedIDs, ID),
      }
      const { dateReceived } = messageItem

      if (dateReceived !== lastDateAdded) {
        const dateItem = createDateItem(messageItem)

        thread = [...thread, dateItem]
        lastDateAdded = dateReceived
      }

      thread = [...thread, messageItem]
    })

  return thread.filter(Boolean)
}

// State setters
const populateThread = setThread => rawData => setThread(assembleThread(rawData))

const handleIncomingMessage = (thread, setThread, outgoing, setOutgoing) => message => {
  const transformed = transformMessage(message)

  let toAppend = [transformed]

  const { dateReceived } = transformed
  const messageBefore = thread[thread.length - 1]
  const dateBeforeMessage = messageBefore ? messageBefore.dateReceived : null

  if (!messageBefore || dateReceived !== dateBeforeMessage) {
    toAppend = [createDateItem(transformed), ...toAppend]
  }

  const append = withAppend(setThread)

  const { metadata } = message

  if (metadata && metadata.outgoingID) {
    const withRemovedOutgoing = removeMessageByID(outgoing, metadata.outgoingID)

    append(...toAppend)
    setOutgoing(withRemovedOutgoing)
  } else {
    append(...toAppend)
  }
}

const tagSuccessfulDelivery = (initial, setter) => payload => {
  const { ID, receivingChannel } = payload
  const updated = updateWhere(initial, {
    where: { ID, metadata: { receivingChannel } },
    values: { status: 'DELIVERED' },
  })

  setter(updated)
}

const tagSeen = (initial, setter) => channel => {
  const unread = initial.filter(({
    seen,
    role,
    metadata,
  }) => {
    const meetsConditions = !seen
      && role === 'appMaker'
      && metadata
      && metadata.receivingChannel === channel

    return meetsConditions
  }).map(({ ID }) => ({
    where: { ID },
    values: { seen: true },
  }))

  if (unread.length > 0) {
    const thread = unread.reduce(updateWhere, initial)

    setter(thread)
  }

  return null
}

const tagFailed = (initial, setter) => ID => {
  const updated = updateWhere(initial, {
    where: { ID },
    values: { status: 'FAILED' }
  })

  setter(updated)
}

const removeMessage = (initial, setter) => ID => setter(removeMessageByID(initial, ID))

// TODO: Use with request debouncing later
const removeMessages = (initial, setter) => IDs => { // eslint-disable-line
  const withRemoved = IDs.reduce(removeMessage, initial)

  // Make all the updates before setting thread
  setter(withRemoved)
}

// Main
const useMessages = () => {
  const [thread, setThread] = useState([])
  const [outgoing, setOutgoing] = useState([])

  return {
    // State
    thread,
    outgoing,
    // State setters
    populateThread: populateThread(setThread),
    appendToOutgoing: withAppend(setOutgoing),
    handleIncomingMessage: handleIncomingMessage(thread, setThread, outgoing, setOutgoing),
    tagSuccessfulDelivery: tagSuccessfulDelivery(thread, setThread),
    tagSeen: tagSeen(thread, setThread),
    tagFailed: tagFailed(thread, setThread),
    removeMessage: removeMessage(thread, setThread),
  }
}

// Exports
export default useMessages
