import React from 'react'
// Env
import { OUTGOING_HOST } from 'env'

// Hooks
import { useSocket } from './hooks'

// Component
import App from './App'

// Main
const AppContainer = routeProps => {
  const { match: { params: { recipientID } } } = routeProps
  const socket = useSocket(OUTGOING_HOST, {
    path: '/socket',
    query: { recipientID },
  })

  const props = {
    ...routeProps,
    socket,
    recipientID,
  }

  return (<App {...props} />)
}

export default AppContainer
