import React from 'react'
import PropTypes from 'prop-types'
import { Formik, FieldArray } from 'formik'
import { Button, Input } from 'antd'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'

import { ContentWrapper, ControlsWrapper} from './styled'
class SendListings extends React.PureComponent {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  renderField = (arrayHelpers, props) => (listingLink, index) => {
    return (
      <div className="input-wrapper" key={`listingLinks.${index}`}>
        <Input
          placeholder="Listing Link"
          name={`listingLinks.${index}`}
          onChange={props.handleChange}
          value={listingLink}
        />
        <Button
          type="Button"
          icon={(<MinusOutlined />)}
          onClick={() => {
            arrayHelpers.remove(index)
          }}
          disabled={props.values.listingLinks.length === 1}
        />
        <Button
          type="Button"
          icon={(<PlusOutlined />)}
          onClick={() => arrayHelpers.insert(index + 1, '')}
          disabled={props.values.listingLinks.length === 10}
        />
      </div>
    )
  }

  handleSubmit = values => {
    const { onSubmit } = this.props
    const formValues = values.listingLinks
      .filter(links => links.length !== 0)
      .map(link => {
        const cleanString = link.replace(/(http|https):\/\//g, '')
        const splitString = cleanString.split('/')
        const listingID = splitString[splitString.length - 1]

        return listingID
      })

    onSubmit(formValues, 'sendListings')
  }

  render() {
    const { onCancel } = this.props

    return (
      <Formik
        initialValues={{ listingLinks: ['', ''] }}
        onSubmit={this.handleSubmit}
      >
        {props => (
          <form onSubmit={props.handleSubmit}>
            <ContentWrapper>
              <h3>Send Listing(s)</h3>
              <FieldArray
                name="listingLinks"
                render={arrayHelpers => props.values.listingLinks.map(this.renderField(arrayHelpers, props))
                }
              />
            </ContentWrapper>
            <ControlsWrapper>
              <Button onClick={onCancel}>Cancel</Button>
              <Button onClick={props.handleSubmit}>Submit</Button>
            </ControlsWrapper>
          </form>
        )}
      </Formik>
    )
  }
}

export default SendListings
