import React from 'react'
import ReactDOM from 'react-dom'
import AppContainer from './App'

import {BrowserRouter as Router, Route} from 'react-router-dom'

ReactDOM.render(
<Router>
  <Route path="/:recipientID" component={AppContainer} exact/>
</Router>,
document.getElementById('root'))
