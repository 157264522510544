import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  Dropdown,
  Button,
  Menu,
  Input
} from 'antd'
import { UpOutlined } from '@ant-design/icons'
import uuid from 'uuid/v4'

const DEFAULT_STATE = {
  text: '',
  menuIsOpen: false,
  modalIsOpen: false,
}

const Wrapper = styled.div.attrs({
  className: 'controls-wrapper',
})`
  display: flex;

  padding: 0 0.5rem;
  position: relative;

  button {
    align-self: flex-end;
    flex: 0 0 auto;
    margin: 1rem 0.5rem;

    &:disabled {
      opacity: 0.3;
    }
  }

  textarea {
    align-self: center;
    flex: 1 1 auto;
    margin: 1rem 0.5rem;
  }

  .channelSelector {
    align-self: flex-start;
  }
`

// TODO: Find out whether it is better to convert this class component
// to a functional component that uses hooks for state management.
export default class Controls extends React.PureComponent {
  // Componenet properties
  static defaultProps = {
    clients: [],
    error: null,
    isReady: false,
    receivingChannel: null,
  }
  static propTypes = {
    actions: PropTypes.array.isRequired,
    onChannelSelect: PropTypes.func.isRequired,
    onFormSelect: PropTypes.func.isRequired,
    clients: PropTypes.array,
    error: PropTypes.string,
    isReady: PropTypes.bool,
    receivingChannel: PropTypes.string,
  }

  // Class properties
  // Add more supported channels here in the future
  channelDisplayNames = {
    twilio: 'SMS',
    messenger: 'FBM',
  }
  channelMenuDisplayNames = {
    twilio: 'SMS',
    messenger: 'Facebook Messenger (FBM)'
  }

  // Component state
  state = DEFAULT_STATE

  // Methods
  // All bound to `this` by () => {} class method syntax
  // because they use `setState` directly and indirectly
  handleChange = e => this.setState({ text: e.target.value })

  send = e => {
    e.preventDefault()

    const { text } = this.state

    const afterSetState = () => {
      const { onSend } = this.props

      onSend({
        outgoingID: uuid(),
        text,
      })
    }

    this.setState({
      ...this.state,
      text: '',
      menuIsOpen: false,
      modalIsOpen: false,
    }, afterSetState)
  }

  toggleOptionsMenu = () => {
    this.setState(({ menuIsOpen }) => ({ menuIsOpen: !menuIsOpen }))
  }

  toggleModal = () => {
    this.setState(({ modalIsOpen }) => ({ modalIsOpen: !modalIsOpen }))
  }

  closeModal = () => this.setState({ modalIsOpen: false })

  handleActionClick = handler => {
    this.toggleOptionsMenu()

    handler()
  }

  handleModalDisplay = formToDisplay => {
    const { onFormSelect } = this.props

    this.toggleOptionsMenu()

    onFormSelect(formToDisplay)
  }

  renderAction = ({
    label,
    handler,
    type,
    formToDisplay,
  }) => {
    let handleClick = null

    if (type === 'action') {
      handleClick = () => this.handleActionClick(handler)
    } else if (type === 'form') {
      handleClick = () => this.handleModalDisplay(formToDisplay)
    }

    return (
      <Menu.Item
        onClick={handleClick}
        key={label}
      >
        {label}
      </Menu.Item>
    )
  }

  renderClient = ({ platform }) => {
    const { onChannelSelect } = this.props
    const handleClick = () => onChannelSelect(platform)

    return (
      <Menu.Item
        onClick={handleClick}
        key={platform}
      >
        {this.channelMenuDisplayNames[platform]}
      </Menu.Item>
    )
  }

  renderChannelsMenu(clients) {
    return (
      <Menu>
        <Menu.Item disabled>Select a channel to use</Menu.Item>
        {clients.map(this.renderClient)}
      </Menu>
    )
  }

  // Main render method
  // What the component will finally look like
  render() {
    const {
      actions,
      clients,
      error,
      isReady,
      receivingChannel,
      loading,
    } = this.props
    const { text } = this.state

    const actionsMenu = (<Menu>{actions.map(this.renderAction)}</Menu>)

    // Things to base disabled state on
    const noMessage = text.length === 0
    const noClients = clients.length === 0
    const isSMS = receivingChannel === 'twilio'

    return (
      <Wrapper>
        { receivingChannel && (
          <Dropdown
            className="channelSelector"
            placement="topRight"
            overlay={this.renderChannelsMenu(clients)}
            disabled={loading || !!error || noClients || !isReady}
          >
            <Button>{this.channelDisplayNames[receivingChannel]} <UpOutlined /></Button>
          </Dropdown>
        )}
        <Input.TextArea
          // Component options
          autoSize={{ minRows: 1, maxRows: 6 }}
          disabled={loading || !!error || noClients || !isReady}
          // User input props
          onChange={this.handleChange}
          onPressEnter={this.send}
          // Value props
          defaultValue={text}
          value={text}
        />
        <Button
          type="primary"
          onClick={this.send}
          disabled={loading || !!error || noClients || noMessage}
        >
          Send
        </Button>
        <Dropdown
          placement="topRight"
          overlay={actionsMenu}
          disabled={loading || !!error || noClients || isSMS || !isReady}
        >
          <Button>Actions <UpOutlined /></Button>
        </Dropdown>
      </Wrapper>
    )
  }
}
