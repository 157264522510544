// NPM
import React, { useState } from 'react'
import styled from 'styled-components'
import propTypes from 'prop-types'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

// Styled components
const ImageFrame = styled.div.attrs({
  className: 'imageFrame',
})`
  background-color: ${({ loading }) => loading === 'true' ? 'rgba(255, 255, 255, 0.1)' : 'transparent'};
  border-radius: 5px;
  overflow: hidden;
  text-align: center;

  display: flex;
  justify-content: center;
  flex-wrap: nowrap;

  /*
  * Based to Bubble component size
  * Its max-width is 360px
  * So minus 1rem (16px) left & right, because its padding is 1rem
  * That gives an imageFrame width of 328px
  *
  * Set max-height to roughly maintain a 16:9 aspect ratio for portrait
  * oriented images
  */
  max-height: 583px;

  height: ${({ loading }) => loading === 'true' ? '3rem' : 'auto'};
  width: 100%;

  & + p,
  & + h2 {
    margin-top: 1rem;
  }

  img {
    display: ${({ loading }) => loading === 'true' ? 'none' : 'block'};

    position: relative;
    left: 50%;
    transform: translateX(-50%);

    height: auto;
    width: 100%;
  }

  .ant-spin {
    align-self: center;
  }
`

ImageFrame.displayName = 'ImgFrame'

// Main
const Image = ({
  src,
  alt,
  onImageLoaded,
}) => {
  const [loading, setLoading] = useState(true)
  const handleImageLoaded = () => {
    setLoading(false)

    const handlePaintFinished = () => onImageLoaded()

    // Triggers a repaint, i.e. let's image render in browser before
    // calling `onImageLoaded` prop
    window.requestAnimationFrame(handlePaintFinished)
  }
  const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />

  return (
    // loading.toString() is workaround for this issue:
    // https://github.com/styled-components/styled-components/issues/439
    <ImageFrame loading={loading.toString()}>
      {loading && <Spin indicator={antIcon} />}
      {<img src={src} alt={alt} onLoad={handleImageLoaded} />}
    </ImageFrame>
  )
}

Image.defaultProps = {
  onImageLoaded: () => null
}
Image.propTypes = {
  src: propTypes.string.isRequired,
  alt: propTypes.string.isRequired,
  onImageLoaded: propTypes.func,
}

// Exports
export { Image }
