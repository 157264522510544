// NPM
import { useState, useEffect } from 'react'
import client from 'socket.io-client'

// Main
const useSocket = (...config) => {
  const [socket] = useState(client(...config))

  useEffect(() => () => socket.close(), [socket])

  return socket
}

// Exports
export default useSocket
