// NPM
import React from 'react'
import styled from 'styled-components'
import propTypes from 'prop-types'
import { Button } from 'antd'

// Internal components
import { Image } from './components'

// Styled components
export const Wrapper = styled.section.attrs({
  className: 'message__bubble',
})`
  align-self: flex-end;
  color: white;
  font-size: 0.875rem;
  font-family: sans-serif;
  flex: 0 0 auto;
  height: auto;
  line-height: 1.35em;
  max-width: 360px;
  width: auto;
  margin-bottom: 0;
  padding: 1rem;
  text-align: left;

  white-space: pre-line;

  & + & {
    margin-left: 1rem;
  }

  h2 {
    color: #fff;
    font-size: inherit;
    font-weight: bold;
  }

  p {
    margin: 0;
  }

  p.description {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  p + .actions {
    margin-top: 1rem;
  }

  .actions {
    button {
      background-color: #54aba8;
      border-color: #54aba8;
    }

    button + button {
      margin-top: 0.5rem;
    }
  }
`

Wrapper.displayName = 'Bubble'

// Lib
const renderActions = actions => {
  return (
    <div className="actions">
      {actions.map(({ text }) => (
        <Button
          type="primary"
          shape="round"
          key={text}
          block
        >
          {text}
        </Button>
      ))}
    </div>
  )
}

// Main
export const Bubble = ({
  actions,
  description,
  ID,
  onImageLoaded,
  mediaUrl,
  text,
  title,
}) => {
  const buttonsActions = actions.filter(({ type }) => (
    type === 'webview'
    || type === 'postback'
    || type === 'link'
  ))

  const handleImageLoaded = () => onImageLoaded(ID)

  const content = description || text
  const imageProps = {
    alt: 'Message image',
    onImageLoaded: handleImageLoaded,
    src: mediaUrl,
  }

  return (
    <Wrapper>
      {mediaUrl && (<Image { ...imageProps } />)}
      {title && <h2>{title}</h2>}
      {content && <p className={description && 'description'}>{content}</p>}
      {buttonsActions.length > 0 && renderActions(buttonsActions)}
    </Wrapper>
  )
}

// Props
Bubble.defaultProps = {
  actions: [],
  description: null,
  ID: null,
  isLastItem: false,
  mediaUrl: null,
  onImageLoaded: () => null,
  text: null,
  title: null,
}
Bubble.propTypes = {
  actions: propTypes.array,
  description: propTypes.string,
  ID: propTypes.string,
  isLastItem: propTypes.bool,
  mediaUrl: propTypes.string,
  onImageLoaded: propTypes.func,
  text: propTypes.string,
  title: propTypes.string,
}

