import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Field } from 'formik'
import { Button } from 'antd'
import { 
  ContentWrapper, 
  ControlsWrapper, 
  TextArea
} from './styled'

import { DEFAULT_BROWSE_LISTING_MESSAGE as defaultText } from './constants'

class SendListings extends React.PureComponent {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  handleSubmit = values => {
    const { onSubmit } = this.props

    onSubmit(values.text, 'sendBrowseListings')
  }

  renderField = ({field, form, props}) => <TextArea rows={4} {...field} {...props}/>
  
  render() {
    const { onCancel } = this.props

    return (
      <Formik
        initialValues={{ text: defaultText }}
        onSubmit={this.handleSubmit}
      >
        {props => (
          <form onSubmit={props.handleSubmit}>
            <ContentWrapper>
              <h3>Send BrowseListings</h3>
              <div className="input-wrapper">
                <Field 
                  name="text"
                  component={this.renderField}
                />
              </div>
            </ContentWrapper>
            <ControlsWrapper>
              <Button onClick={onCancel}>Cancel</Button>
              <Button onClick={props.handleSubmit}>Submit</Button>
            </ControlsWrapper>
          </form>
        )}
      </Formik>
    )
  }
}

export default SendListings
