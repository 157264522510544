//NPM
import styled from 'styled-components'

// Main
export const Wrapper = styled.main.attrs(() => ({
  className: 'messageThread',
}))`
  border-bottom: 1px solid #eee;

  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;

  position: relative;

  & > .ant-btn {
    position: fixed;
    z-index: 9999;
    bottom: 6rem;
    left: 50%;
    transform: translateX(-50%);
  }

  & > .ant-spin {
    padding: 2rem;
  }
`
Wrapper.displayName = 'Wrapper'
