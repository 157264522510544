// NPM
import React from 'react'
import styled from 'styled-components'
import propTypes from 'prop-types'
import { Tooltip, Button } from 'antd'
import { ReloadOutlined, DeleteOutlined } from '@ant-design/icons'

// Styled components
const Wrapper = styled.div.attrs({
  className: 'resend',
})`
  display: flex;

  align-self: center;
  flex: 0 0 auto;

  margin-right: 0.5rem;
`
Wrapper.displayName = 'ResendControls'

// Main
export const ResendControls = ({ onCommand }) => {
  const handleRemove = () => onCommand('remove')
  const handleResend = () => onCommand('resend')

  return (
    <Wrapper>
      <Tooltip title="Remove">
        <Button onClick={handleRemove} type="link" icon={<DeleteOutlined />} />
      </Tooltip>
      <Tooltip title="Resend">
        <Button onClick={handleResend} type="link" icon={<ReloadOutlined />} />
      </Tooltip>
    </Wrapper>
  )
}

// Props
ResendControls.defaultProps = {
  onCommand: () => null,
}
ResendControls.propTypes = {
  onCommand: propTypes.func,
}
