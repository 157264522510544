// NPM
import React, { useState, useEffect } from 'react'
import { notification } from 'antd'
import queryString from 'query-string'

// Hooks
import { useMessages, useSocketEvents } from './hooks'

import {
  GlobalStyles,
  Layout,
  MessageThread,
  Controls,
  Modal,
  SendListings,
  SendBrowseListings,
  SendApplyToListing
} from 'components'

const App = ({
  location,
  socket,
  recipientID,
}) => {
  const [receivingChannel, setReceivingChannel] = useState(null)
  const [clients, setClients] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [isReady, setIsReady] = useState(false)

  const {
    thread,
    outgoing,
    populateThread,
    appendToOutgoing,
    handleIncomingMessage,
    tagSuccessfulDelivery,
    tagSeen,
    tagFailed,
    removeMessage,
  } = useMessages()

  const openNotification = (text) => notification['error']({
    message: 'Send Failed Error',
    description: text,
  })

  useSocketEvents(socket, [
    {
      event: 'initialize',
      handler: ({ clients, error, ...rest }) => {
        if (error) {
          setError(error)
          socket.close()
        } else {
          populateThread(rest)
          setClients(clients)
        }

        setLoading(false)
      }
    },
    {
      event: 'incomingMessage',
      handler: ({ message }) => {
        const { role, source: { type } } = message

        // We can assume that when a user replies to an SMS, all
        // previous SMS messages have been SMS messages have been seen
        if (role === 'appUser' && type === 'twilio') {
          tagSeen(type)
        }

        handleIncomingMessage(message)

        // Make sure the next reply is to whatever channel last used
        if (role === 'appUser') {
          setReceivingChannel(type)
        }
      },
    },
    {
      event: 'sendError',
      handler: openNotification
    },
    {
      event: 'deliverySucessful',
      handler: tagSuccessfulDelivery,
    },
    {
      event: 'seen',
      handler: () => tagSeen('messenger')
    },
    {
      event: 'sendFailed',
      handler: tagFailed
    },
  ])

  const [modal, setModal] = useState({
    formToDisplay: null,
    isOpen: false,
  })

  const setModalFormToDisplay = formToDisplay => setModal(current => ({ ...current, formToDisplay }))
  const setModalIsOpen = (isOpen = true) => setModal(current => ({ ...current, isOpen }))

  const send = ({
    originalChannel,
    originalID,
    originalSender,
    outgoingID,
    ...payload
  }) => {
    const { sender } = queryString.parse(location.search)

    if (originalID) {
      removeMessage(originalID)
    }

    const messagePayload = {
      metadata: {
        sender,
        receivingChannel,
        outgoingID,
        originalID,
        // Might be useful down the road
        originalChannel,
        originalSender,
      },
      ...(clients.length > 1 && {
        destination: {
          integrationType: receivingChannel,
        }
      }),
      role: 'appMaker',
      type: 'text',
      ...payload,
    }

    appendToOutgoing({
      ...messagePayload,
      ID: outgoingID,
      status: 'SENDING',
    })

    socket.emit('sendMessage', messagePayload)
  }

  const handleFormSelect = form => {
    setModalIsOpen()
    setModalFormToDisplay(form)
  }
  const closeModal = () => setModalIsOpen(false)
  const renderModalContent = formToDisplay => {
    const commonFormProps = {
      onCancel: closeModal,
      onSubmit: handleSubmit,
    }

    const forms = {
      sendListings: (<SendListings {...commonFormProps} />),
      sendBrowseListings: (<SendBrowseListings {...commonFormProps} />),
      sendApplyToListing: (<SendApplyToListing {...commonFormProps} />)
    }

    if (!formToDisplay) {
      return null
    }

    return forms[formToDisplay]
  }
  const { formToDisplay, isOpen } = modal
  const handleSubmit = (values, name) => {
    closeModal()

    const p = { receivingChannel, payload: values }

    switch(name) {
      case 'sendListings':
        socket.emit('sendListings', p)
        break
      case 'sendBrowseListings':
        socket.emit('sendBrowseListings', p)
        break
      case 'sendApplyToListing':
        socket.emit('sendApplyToListing', p)
        break;
      default:
        return null
    }
  }
  const findListings = () => socket.emit('getDesiredProperty', recipientID)
  const userMessages = !loading && thread.filter(({ role }) => role === 'appUser')

  useEffect(() => {
    if (!loading && !receivingChannel) {
      let defaultChannel = null

      const lastUserChannel = userMessages[userMessages.length - 1]

      if (lastUserChannel) {
        defaultChannel = lastUserChannel.source.type
      } else {
        const foundPrimaryChannel = clients.find(({ primary }) => primary)

        defaultChannel = foundPrimaryChannel ? foundPrimaryChannel.platform : null
      }

      setReceivingChannel(defaultChannel)
    }

  }, [userMessages, loading, receivingChannel, clients])

  const handleChannelSelect = channel => setReceivingChannel(channel)

  const remove = ({ originalID }) => {
    removeMessage(originalID)
    socket.emit('removeMessage', originalID)
  }
  // Resend payload includes `originalID`
  // See resend functions section of `Message` component
  // See `sendMessage` socket event on `mailbox`
  const resend = payload => send(payload)

  const handleThreadReady = () => setIsReady(true)

  return (
    <Layout>
      <GlobalStyles />
      <MessageThread
        thread={thread}
        outgoing={outgoing}
        loading={loading}
        clients={clients}
        onRemove={remove}
        onResend={resend}
        onThreadReady={handleThreadReady}
        error={error}
      />
      <Controls
        isReady={isReady}
        clients={clients}
        onChannelSelect={handleChannelSelect}
        receivingChannel={receivingChannel}
        onSend={send}
        actions= {[
          {
            label: 'Get desired property',
            type: 'action',
            handler: findListings
          },
          {
            label: 'Send listing(s)',
            type: 'form',
            formToDisplay: 'sendListings',
          },
          {
            label: 'Send browse listings',
            type: 'form',
            formToDisplay: 'sendBrowseListings'
          },
          {
            label: 'Send Apply To Listing',
            type: 'form',
            formToDisplay: 'sendApplyToListing'
          }
        ]}
        onFormSelect={handleFormSelect}
        error={error}
      />
      <Modal isOpen={isOpen}>{renderModalContent(formToDisplay)}</Modal>
    </Layout>
  )
}

export default App
