// NPM
import React, { useState, useRef, useEffect } from 'react'
import propTypes from 'prop-types'
import { Spin, Button } from 'antd'
import { ArrowDownOutlined } from '@ant-design/icons'

// Internal components
import {
  Wrapper,
  Content,
  Header,
  Message,
} from './components'

// Lib
const renderThreadItem = props => messageItem => {
  const { ID, role } = messageItem

  if (!ID || !role) {
    return null
  }

  const Component = role === 'date' ? Header : Message

  return (<Component key={ID} {...(role !== 'date' && props)} { ...messageItem } />)
}

const renderEmptyState = ({ thread, clients, error }) => {
  let text = null

  if (error) {
    text = error
  } else if (clients.length === 0) {
    text = 'No active channels found. You cannot message this user yet.'
  } else if (thread.length === 0) {
    text = 'No messages yet.'
  } else {
    return null
  }

  return (<Header text={text} />)
}

const scrollToBottom = elRef => {
  const { current: scrollView } = elRef
  const { clientHeight, scrollHeight } = scrollView

  scrollView.scrollTop = scrollHeight - clientHeight
}

const shouldScrollToBottom = elRef => {
  const { current: scrollView } = elRef
  const { clientHeight, scrollHeight } = scrollView

  return scrollHeight > clientHeight
}

// Main
const MessageThread = ({
  thread,
  onRemove,
  onResend,
  onThreadReady,
  outgoing,
  loading,
  clients,
  error,
}) => {
  const [isReady, setIsReady] = useState(false)
  const [imagesLoaded, setImagesLoaded] = useState(null)
  const [unreadID, setUnreadID] = useState(null)

  const scrollViewRef = useRef(null)


  useEffect(() => {
    const signalReady = () => {
      setIsReady(true)
      onThreadReady()

      if (shouldScrollToBottom(scrollViewRef)) {
        scrollToBottom(scrollViewRef)
      }
    }

    if (!loading && !isReady) {
      if (thread.length > 0 && !imagesLoaded) {
        let toLoad = {}

        thread
          .filter(({ type }) => type === 'image')
          .forEach(({ ID }) => {
            toLoad = { ...toLoad, [ID]: false }
          })

        setImagesLoaded(toLoad)
      } else if (imagesLoaded) {
        const imageLoadedKeys = Object.keys(imagesLoaded)

        if (imageLoadedKeys.length === 0) {
          signalReady()
        } else if (!(imageLoadedKeys.map(key => imagesLoaded[key])).includes(false)) {
          signalReady()
        }
      }
    }

    // Called once on load and for outgoing messages
    // Called twice for incoming images:
    // Once for initial receipt and another time when fully rendered
    if (!loading && isReady && scrollViewRef.current) {
      if (shouldScrollToBottom(scrollViewRef)) {
        const { current: scrollView } = scrollViewRef

        // Always scroll to bottom when sending messages
        if (outgoing.length > 0 ) {
          scrollToBottom(scrollViewRef)
        }

        const { scrollTop, scrollHeight, clientHeight } = scrollView
        const { ID } = thread[thread.length - 1]
        const lastMessage = document.getElementById(ID)
        const { height } = lastMessage.getBoundingClientRect()

        const maxScrollTop = scrollHeight - clientHeight

        if (scrollTop < maxScrollTop - height) {
          // Set once to make sure it scrolls to first unread message
          if (!unreadID) {
            setUnreadID(ID)
          }
        } else {
          scrollToBottom(scrollViewRef)
        }
      }
    }
  }, [loading, thread, outgoing, isReady, imagesLoaded, onThreadReady, scrollViewRef, unreadID])

  const onImageLoaded = ID => setImagesLoaded(curr => ({ ...curr, [ID]: true }))
  const renderItem = renderThreadItem({ onResend, onRemove, onImageLoaded })
  const threadIsReady = !loading && isReady
  const handleScroll = () => {
    if (unreadID) {
      const unreadIDMessage = document.getElementById(unreadID)
      const { y } = unreadIDMessage.getBoundingClientRect()

      const { current: scrollView } = scrollViewRef
      const { clientHeight } = scrollView

      if (unreadID !== thread[thread.length - 1].ID) {
        const { ID } = thread[thread.length - 1]

        setUnreadID(ID)
      } else if (y <= clientHeight) {
        setUnreadID(null)
      }
    }
  }

  return (
    <Wrapper ref={scrollViewRef} onScroll={handleScroll}>
      {!threadIsReady && <Spin size="large" />}
      {unreadID && (
        <Button
          type="primary"
          shape="round"
          icon={<ArrowDownOutlined />}
          href={`#${unreadID}`}
        >
          New Message(s)
        </Button>
      )}
      <Content isReady={threadIsReady.toString()}>
        {renderEmptyState({ thread, clients, error })}
        {thread.map(renderItem)}
        {outgoing.map(renderItem)}
      </Content>
    </Wrapper>
  )
}
// Prop types
MessageThread.defaultProps = {
  clients: [],
  error: null,
  onRemove: () => null,
  onResend: () => null,
  thread: [],
  outgoing: [],
  loading: false,
}
MessageThread.propTypes = {
  clients: propTypes.array,
  error: propTypes.string,
  onRemove: propTypes.func,
  onResend: propTypes.func,
  thread: propTypes.array,
  outgoing: propTypes.array,
  loading: propTypes.bool,
}

// Exports
export default MessageThread
