import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Overlay = styled.div.attrs({
  className: 'overlay',
})`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background-color: rgba(0, 0, 0, 0.15);
`
const ModalWrapper = styled.div.attrs({
  className: 'modal',
})`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
`

class Modal extends React.PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.node,
    ]),
    isOpen: PropTypes.bool,
  }

  static defaultProps = {
    children: null,
    isOpen: false,
  }

  constructor(props) {
    super(props)

    this.portal = document.getElementById('portal')
  }

  render() {
    const { children, isOpen } = this.props

    if (!children || !isOpen) {
      return null
    }

    return ReactDOM.createPortal((
      <Overlay>
        <ModalWrapper>
          {children}
        </ModalWrapper>
      </Overlay>
    ), document.getElementById('portal'))
  }
}

export default Modal
