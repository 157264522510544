//NPM
import styled from 'styled-components'

// Main
export const Content = styled.article.attrs(() => ({
  className: 'messageThread__content',
}))`
  opacity: ${({ isReady }) => isReady === 'true' ? 1 : 0};

  transition: opacity 300ms;
  transition-delay: 1000;
`
Content.displayName = 'Content'
